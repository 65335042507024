<style scoped>
.ivu-table .demo-table-info-row td {
    background-color: #2db7f5;
    color: #fff;
    padding-left: 3px !important;
    padding-right: 3px !important;
}
.ivu-table td.demo-table-info-column {
    background-color: #2db7f5;
    color: #fff;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
<template>
    <div>
        <div class="demo-spin-article">
            <div class="row-md-11 tw-justify-between tw-flex">
                <div class="col-md-4">
                    <div>
                        <label class="form-col-label control-label required">{{
                            $t("approvalFlow.approvalType")
                        }}</label>
                        <select
                            v-model.number.trim="model.app_type_id"
                            class="form-select"
                            :class="{ 'is-invalid': errors.has('app_type_id') }"
                            ref="app_type_id"
                            @change="getParameter"
                        >
                            <option :value="null" selected>
                                {{ $t("select") }}
                            </option>
                            <option
                                v-for="(approvalType, index) in approvalTypes"
                                :key="index"
                                :value="approvalType.app_type_id"
                                >{{ approvalType.app_types }}</option
                            >
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('app_type_id')"
                        >
                            {{ errors.first("app_type_id") }}
                        </div>
                    </div>
                    <div class="tw-flex tw-mt-2">
                        <div class="tw-mr-2 tw-w-1/2">
                            <label class="form-col-label control-label">{{
                                $t("approvalFlow.escalateAfterHours")
                            }}</label>
                            <input
                                type="text"
                                v-model="model.escalate_after_xx_hours"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has(
                                        'escalate_after_xx_hours'
                                    )
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('escalate_after_xx_hours')"
                            >
                                {{ errors.first("escalate_after_xx_hours") }}
                            </div>
                        </div>

                        <div class="tw-w-1/2">
                            <label class="form-col-label control-label">{{
                                $t("approvalFlow.numberOfEscalate")
                            }}</label>
                            <input
                                type="text"
                                v-model="model.escalate_times"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('escalate_times')
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('escalate_times')"
                            >
                                {{ errors.first("escalate_times") }}
                            </div>
                        </div>
                    </div>

                    <div class="tw-mt-2">
                        <label class="form-col-label control-label">{{
                            $t("approvalFlow.approverMobileTemplate")
                        }}</label>
                        <textarea
                            v-model="model.approver_notification_template"
                            rows="2"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has(
                                    'approver_notification_template'
                                )
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('approver_notification_template')"
                        >
                            {{ errors.first("approver_notification_template") }}
                        </div>
                    </div>
                    <div class="tw-mt-2">
                        <label class="form-col-label control-label">{{
                            $t("approvalFlow.initiatorMobileTemplate")
                        }}</label>
                        <textarea
                            v-model="model.initiator_notification_template"
                            rows="3"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has(
                                    'initiator_notification_template'
                                )
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('initiator_notification_template')"
                        >
                            {{
                                errors.first("initiator_notification_template")
                            }}
                        </div>
                    </div>
                </div>

                <div class="col-md-4 tw-ml-5">
                    <div>
                        <label class="form-col-label control-label">{{
                            $t("approvalFlow.emailSubject")
                        }}</label>
                        <input
                            type="text"
                            v-model="model.email_subject"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('email_subject')
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('email_subject')"
                        >
                            {{ errors.first("email_subject") }}
                        </div>
                    </div>

                    <div class="tw-mt-2">
                        <label class="form-col-label control-label">{{
                            $t("approvalFlow.approverEmailTemplate")
                        }}</label>
                        <textarea
                            v-model="model.approver_email_template"
                            rows="5"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has(
                                    'approver_email_template'
                                )
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('approver_email_template')"
                        >
                            {{ errors.first("approver_email_template") }}
                        </div>
                    </div>
                    <div class="tw-mt-4">
                        <label class="form-col-label control-label">{{
                            $t("approvalFlow.initiatorEmailTemplate")
                        }}</label>
                        <textarea
                            v-model="model.initiator_email_template"
                            rows="3"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has(
                                    'initiator_email_template'
                                )
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('initiator_email_template')"
                        >
                            {{ errors.first("initiator_email_template") }}
                        </div>
                    </div>
                </div>

                <div class="col-md-3 tw-ml-5">
                    <div class="alert alert-success tw-text-center">
                        <label
                            class="form-col-label control-label tw-text-xl tw-font-bold tw-pt-5"
                            >{{ $t("approvalFlow.parameters") }}
                            <Tooltip placement="top">
                                <i
                                    class="fas fa-circle-notch fa-spin"
                                    v-if="this.ParameterLoading == true"
                                ></i>
                                <Icon
                                    v-else
                                    type="md-help-circle"
                                    :wait="ParameterLoading"
                                    size="20"
                                />
                                <div slot="content">
                                    <div class="tw-text-xs">
                                        Use this parameters to create template
                                    </div>
                                </div>
                            </Tooltip>
                        </label>
                        <br />
                        <template>
                            <Tooltip placement="top">
                                <a
                                    v-for="(approvalFlowParameter,
                                    index) in approvalFlowParameters"
                                    :key="index"
                                    v-clipboard:copy="
                                        approvalFlowParameter.parameter
                                    "
                                    @click="copied"
                                >
                                    <br />{{ approvalFlowParameter.parameter }}
                                </a>
                                <div slot="content">
                                    <div class="tw-text-xs">
                                        Click to copy parameter
                                    </div>
                                </div>
                            </Tooltip>
                        </template>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('initiator_notification_template')"
                        >
                            {{
                                errors.first("initiator_notification_template")
                            }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- approvalFlow -->

            <div class="tw-mt-10">
                <fieldset>
                    <legend class="text-blue">
                        {{ $t("approvalFlow.pageTitle") }}
                        <Tooltip placement="top">
                            <Icon
                                type="ios-information-circle-outline"
                                size="17"
                            />
                            <div slot="content">
                                <div class="tw-text-xs">
                                    Appproval Flow.
                                </div>
                            </div>
                        </Tooltip>
                    </legend>
                    <div
                        class="tw-mt-2 overflow-auto tw-max-h-40"
                        style="min-height: 100px;"
                    >
                        <div class="table-ui-detail">
                            <table>
                                <thead>
                                    <tr>
                                        <th width="15%">
                                            {{ $t("approvalFlow.levelOrder") }}
                                        </th>
                                        <th width="20%">
                                            {{
                                                $t("approvalFlow.approverType")
                                            }}
                                        </th>
                                        <th width="20%">
                                            {{ $t("approvalFlow.approver") }}
                                        </th>
                                        <th width="20%">
                                            {{
                                                $t(
                                                    "approvalFlow.approverTypeCC"
                                                )
                                            }}
                                        </th>
                                        <th width="20%">
                                            {{ $t("approvalFlow.approverCC") }}
                                        </th>
                                        <th
                                            style="text-align:center; width: 1px;"
                                            width="5%"
                                        >
                                            <Tooltip
                                                placement="top"
                                                :transfer="true"
                                                :content="$t('add')"
                                            >
                                                <a
                                                    href="#"
                                                    class="text-primary"
                                                    @click.prevent="onAddItem()"
                                                >
                                                    <i
                                                        class="fas fa-plus"
                                                    ></i> </a
                                            ></Tooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(detail, index) in items"
                                        :key="index"
                                    >
                                        <td class="text-center">
                                            <Input
                                                v-model.number="
                                                    detail.level_order
                                                "
                                                style="width: 100%"
                                                :class="{
                                                    'ivu-form-item-error': errors.has(
                                                        'details.' +
                                                            index +
                                                            '.level_order'
                                                    )
                                                }"
                                                class="tw-text-right"
                                            >
                                            </Input>
                                        </td>
                                        <td>
                                            <Select
                                                v-model="
                                                    detail.approver_type_id
                                                "
                                                :class="{
                                                    'ivu-form-item-error': errors.has(
                                                        'approver_type_id'
                                                    )
                                                }"
                                                @on-select="
                                                    detail.approver_type_value = null
                                                "
                                            >
                                                <i-Option
                                                    v-for="(approverType,
                                                    index) in approverTypes"
                                                    :key="index"
                                                    :value="
                                                        approverType.approver_type_id
                                                    "
                                                    >{{
                                                        approverType.approver_type
                                                    }}</i-Option
                                                >
                                            </Select>
                                        </td>
                                        <td>
                                            <Select
                                                v-model="
                                                    detail.approver_type_value
                                                "
                                                filterable
                                                :disabled="
                                                    disableApprover(
                                                        detail.approver_type_id
                                                    )
                                                "
                                                :class="{
                                                    'ivu-form-item-error': errors.has(
                                                        'details.' +
                                                            index +
                                                            '.approver_type_value'
                                                    )
                                                }"
                                            >
                                                <template
                                                    v-if="
                                                        detail.approver_type_id ==
                                                            5
                                                    "
                                                >
                                                    <i-Option
                                                        v-for="(logicalPosition,
                                                        index) in logicalPositions"
                                                        :key="index"
                                                        :value="
                                                            logicalPosition.position_id
                                                        "
                                                        >{{
                                                            logicalPosition.position_name_en
                                                        }}
                                                    </i-Option>
                                                </template>

                                                <template
                                                    v-if="
                                                        detail.approver_type_id ==
                                                            6
                                                    "
                                                >
                                                    <i-Option
                                                        v-for="(employee,
                                                        index) in employees"
                                                        :key="index"
                                                        :value="
                                                            employee.employee_id
                                                        "
                                                        >{{
                                                            employee.employee_name_en
                                                        }}
                                                    </i-Option>
                                                </template>
                                            </Select>
                                        </td>
                                        <td>
                                            <Select
                                                v-model="
                                                    detail.approver_type_cc_id
                                                "
                                                @on-select="
                                                    detail.approver_type_cc_value = null
                                                "
                                            >
                                                <i-Option
                                                    v-for="(approverType,
                                                    index) in approverTypes"
                                                    :key="index"
                                                    :value="
                                                        approverType.approver_type_id
                                                    "
                                                    >{{
                                                        approverType.approver_type
                                                    }}</i-Option
                                                >
                                            </Select>
                                        </td>
                                        <td>
                                            <Select
                                                v-model="
                                                    detail.approver_type_cc_value
                                                "
                                                :disabled="
                                                    disableApprover(
                                                        detail.approver_type_cc_id
                                                    )
                                                "
                                            >
                                                <template
                                                    v-if="
                                                        detail.approver_type_cc_id ==
                                                            5
                                                    "
                                                >
                                                    <i-Option
                                                        v-for="(logicalPosition,
                                                        index) in logicalPositions"
                                                        :key="index"
                                                        :value="
                                                            logicalPosition.position_id
                                                        "
                                                        >{{
                                                            logicalPosition.position_name_en
                                                        }}
                                                    </i-Option>
                                                </template>

                                                <template
                                                    v-if="
                                                        detail.approver_type_cc_id ==
                                                            6
                                                    "
                                                >
                                                    <i-Option
                                                        v-for="(employee,
                                                        index) in employees"
                                                        :key="index"
                                                        :value="
                                                            employee.employee_id
                                                        "
                                                        >{{
                                                            employee.employee_name_en
                                                        }}
                                                    </i-Option>
                                                </template>
                                            </Select>
                                        </td>
                                        <td class="text-center">
                                            <Tooltip
                                                placement="top"
                                                :transfer="true"
                                                :content="$t('remove')"
                                            >
                                                <a
                                                    href="#"
                                                    class="text-danger"
                                                    @click.prevent="
                                                        removeRow(detail)
                                                    "
                                                >
                                                    <Icon
                                                        type="ios-trash"
                                                        size="20"
                                                    />
                                                </a>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </fieldset>
                <div class="validate text-danger" v-if="errors.has('details')">
                    {{ errors.first("details") }}
                </div>
            </div>
            <div slot="footer" class="tw-mt-10">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "approvalFlowForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            ParameterLoading: false,
            model: {
                app_type_id: null,
                escalate_after_xx_hours: null,
                escalate_times: null,
                approver_notification_template: null,
                initiator_notification_template: null,
                email_subject: null,
                approver_email_template: null,
                initiator_email_template: null,
                details: []
            }
        };
    },
    computed: {
        ...mapState("setting/approvalFlow", [
            "edit_data",
            "employees",
            "logicalPositions",
            "approvalTypes",
            "approverTypes",
            "approvalFlowParameters"
        ]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        columns() {
            return [
                {
                    title: this.$t("actions"),
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 85
                }
            ];
        },
        items() {
            return this.model.details;
        }
    },
    methods: {
        ...mapActions("setting/approvalFlow", [
            "getApprovalType",
            "getApproverType",
            "getApprovalFlowParameter",
            "clearApprovalFlowParameter"
        ]),
        disableApprover(approver_id) {
            if (approver_id == 5 || approver_id == 6) {
                return false;
            }
            return true;
        },
        copied() {
            this.$Message.success({ content: "Copied", duration: 0.5 });
        },
        async fetchResource() {
            this.loading = true;
            await this.getApprovalType();
            await this.getApproverType();
            this.loading = false;
        },
        async getParameter() {
            this.ParameterLoading = true;
            await this.getApprovalFlowParameter({
                id: this.model.app_type_id ? this.model.app_type_id : ""
            });
            this.ParameterLoading = false;
        },
        onAddItem() {
            this.model.details.push({
                id: 0,
                level_order: this.model.details.length + 1,
                approver_type_id: null,
                approver_type_value: null,
                approver_type_cc_id: null,
                approver_type_cc_value: null
            });
        },
        removeRow(item) {
            this.model.details.splice(this.model.details.indexOf(item), 1);
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("setting/approvalFlow/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.clearInput();
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("setting/approvalFlow/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("setting/approvalFlow/update", {
                    id: this.edit_data.app_flow_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            await this.fetchResource();
            this.errors = new Errors();
            if (!isEmpty(this.edit_data)) {
                this.model.app_type_id = this.edit_data.app_type_id;
                this.model.escalate_after_xx_hours = this.edit_data.escalate_after_xx_hours;
                this.model.escalate_times = this.edit_data.escalate_times;
                this.model.approver_notification_template = this.edit_data.approver_notification_template;
                this.model.initiator_notification_template = this.edit_data.initiator_notification_template;
                this.model.email_subject = this.edit_data.email_subject;
                this.model.approver_email_template = this.edit_data.approver_email_template;
                this.model.initiator_email_template = this.edit_data.initiator_email_template;
                this.edit_data.approval_request_approve_level.forEach(
                    detail => {
                        this.model.details.push({
                            id: detail.id,
                            level_order: detail.level_order,
                            approver_type_value: detail.approver_type_value,
                            approver_type_id:
                                detail.approver_type.approver_type_id,
                            approver_type_cc_id:
                                detail.approver_type_cc.approver_type_id,
                            approver_type_cc_value:
                                detail.approver_type_cc_value
                        });
                    }
                );
                this.getParameter();
            }
        },
        clearInput() {
            this.clearApprovalFlowParameter();
            this.model.app_type_id = null;
            this.model.escalate_after_xx_hours = null;
            this.model.escalate_times = null;
            this.model.approver_notification_template = null;
            this.model.initiator_notification_template = null;
            this.model.email_subject = null;
            this.model.approver_email_template = null;
            this.model.initiator_email_template = null;
            this.model.search = null;
            this.model.details = [];
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "APPROVAL FLOW",
                desc: not.text
            });
        }
    },
    watch: {}
};
</script>
